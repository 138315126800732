<template>
  <div style="height: 100vh; width: 100vw;">
    <div @click="$router.push('/')" style="padding: 20px 0 0 20px;font-weight: bolder; color: white;font-size: 20px; cursor: pointer;">
      Mortgage Envy
    </div>
    <div
      v-if="loaded"
      class="d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-5 align-self-center"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            :title="questions.questionOne.question"
          >
            You’ll save $2,000 on closing costs when you use both HWA Mortgage and HWA Real Estate.* <br>
            <b-list-group flush>
              <hwa-list-item
                v-for="(ans,index) in questions.questionOne.answers"
                :key="index"
                class="animate__animated animate__fadeIn"
                :title="ans.title"
                :selected="ans.selected"
                @click.native="onSelect(1,index)"
              />
            </b-list-group>
          </app-collapse-item>
          <div
            class="mt-2 "
          />
          <app-collapse-item
            v-if="selectedQ1Answer===0"
            :title="questions.questionTwo.question"
          >
            <b-row>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionTwo.userProvidedAns.first_name"
                  placeholder="First Name"
                />
              </div>
              <div class="col-6">
                <hwa-input
                  v-model="questions.questionTwo.userProvidedAns.last_name"
                  placeholder="Last Name"
                />
              </div>
              <div class="col-12">
                <hwa-input
                  v-model="questions.questionTwo.userProvidedAns.phone"
                  placeholder="Phone"
                />
                By entering your phone number you’re authorizing HWA to use this number to call,
                text and send you messages by any method. We won’t charge you for any contact but your service provider could.
              </div>
            </b-row>
          </app-collapse-item>
          <div class="mt-2" />
          <app-collapse-item
            v-if="selectedQ1Answer===1"
            :title="questions.questionThree.question"
          >
            <b-list-group flush>
              <hwa-list-item
                v-for="(ans,index) in questions.questionThree.answers"
                :key="index"
                class="animate__animated animate__fadeIn"
                :title="ans.title"
                :selected="ans.selected"
                @click.native="onSelect(3,index)"
              />
            </b-list-group>
          </app-collapse-item>
          <div class="mt-2" />
          <app-collapse-item
            v-if="selectedQ3Answer===0"
            title="Great! Let's add their contact info next"
          >
            If you move forward with HWA Mortgage, this lets us update your agent on important things
            like appraisal status and closing. In fact, looping them in can help you close up to 4 days faster.
            We won't ever share anything private like credit reports or bank <br> <br>
            <!--            *View claims.-->
            <b-form-checkbox
              v-model="accept"
            >
              I agree to let HWA Mortgage share application updates with my agent
            </b-form-checkbox>
          </app-collapse-item>
        </app-collapse>
        <div class="mt-2" />

        <div class="d-flex">
          <hwa-button
            icon="ArrowLeftCircleIcon"
            label="PREVIOUS"
            @onClick="$router.back()"
          />
          <div class="ml-1" />
          <hwa-button
            :disabled="!valid"
            label="NEXT"
            :loading="nextLoading"
            @onClick="onNext()"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center full-height"
    >
      <grid-loader
        :loading="true"
        color="white"
      />

    </div>
  </div>
</template>

<script>
import { BListGroup, BRow, BFormCheckbox } from 'bootstrap-vue'
import HwaListItem from '@/layouts/components/HwaListItem.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GridLoader from 'vue-spinner/src/GridLoader.vue'

export default {
  name: 'StartPurchase2',
  components: {
    HwaButton,
    HwaInput,
    HwaListItem,
    BListGroup,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BFormCheckbox,
    GridLoader,
  },
  data() {
    return {
      accept: false,
      loaded: false,
      selectedQ3Answer: null,
      selectedQ1Answer: null,
      nextLoading: false,
      questions: {
        questionOne: {
          id: null,
          question: '',
          answers: [],
          userProvidedAns: {},
        },
        questionTwo: {
          id: null,
          question: '',
          answers: [
          ],
          userProvidedAns: {
            first_name: null,
            last_name: null,
            phone: null,
          },
        },
        questionThree: {
          id: null,
          question: '',
          answers: [
          ],
          userProvidedAns: {},
        },
      },
    }
  },
  computed: {
    valid() {
      if (this.selectedQ1Answer === 0) {
        return this.questions.questionOne.answers.some(q => q.selected)
                  && !!this.questions.questionTwo.userProvidedAns.first_name
                  && !!this.questions.questionTwo.userProvidedAns.last_name
                  && !!this.questions.questionTwo.userProvidedAns.phone
      }
      if (this.selectedQ3Answer === 1) {
        return this.questions.questionOne.answers.some(q => q.selected)
                && this.questions.questionThree.answers.some(q => q.selected)
      }
      return this.questions.questionOne.answers.some(q => q.selected)
              && this.questions.questionThree.answers.some(q => q.selected)
              && this.accept
    },
  },
  watch: {
    selectedQ1Answer(ans) {
      if (ans === 0) {
        this.selectedQ3Answer = null
        this.accept = false
        this.questions.questionThree.answers = this.questions.questionThree.answers.map(q => ({ ...q, selected: false }))
      }
      if (ans === 1) {
        this.questions.questionTwo.userProvidedAns.first_name = null
        this.questions.questionTwo.userProvidedAns.last_name = null
        this.questions.questionTwo.userProvidedAns.phone = null
      }
    },
  },
  mounted() {
    this.getStepQuestions()
  },
  methods: {
    onSelect(questionNo, index) {
      // if q1, set the answered question to determine which other questions to show
      if (questionNo === 1) {
        this.selectedQ1Answer = index
      }
      // same for q2
      if (questionNo === 3) {
        this.selectedQ3Answer = index
      }
      switch (questionNo) {
        case 1:
          this.questions.questionOne.answers = this.questions.questionOne.answers.map(q => ({ ...q, selected: false }))
          this.questions.questionOne.answers[index].selected = !this.questions.questionOne.answers[index].selected
          break
        case 3:
          this.questions.questionThree.answers = this.questions.questionThree.answers.map(q => ({ ...q, selected: false }))
          this.questions.questionThree.answers[index].selected = !this.questions.questionThree.answers[index].selected
          break
      }
    },
    onNext() {
      const id = localStorage.getItem('anonymous')
      this.nextLoading = true
      const completed = (this.selectedQ1Answer === 0 || this.selectedQ3Answer === 1)
      const loggedIn = this.$store.getters['auth/isLoggedIn']
      this.$http.post(`api/questions/purchase/3/${id}?application_id=${this.$store.state.auth.applicationId}&completed=${completed && loggedIn}`, this.questions).then(res => {
        this.nextLoading = false
        this.$store.state.auth.stepThreeCompleted = true
        if (completed) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'All set, sign up.',
              variant: 'success',
            },
          })
          if (loggedIn) {
            this.$router.push('/')
          } else {
            this.$router.push({ name: 'complete', params: { type: 'purchase' } })
          }
        }
        if (this.selectedQ3Answer === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckCircleIcon',
              text: 'Your realtor details.',
              variant: 'success',
            },
          })
          this.$router.push({ name: 'step-4' })
        }
      }).catch(err => {
        this.nextLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed',
            icon: 'CheckCircleIcon',
            text: 'Something went wrong, try again.',
            variant: 'danger',
          },
        })
      })
    },
    getStepQuestions() {
      const id = localStorage.getItem('anonymous')
      this.$http.get(`api/questions/purchase/3/${id}?update=${this.$store.state.auth.updateRequest}&application_id=${this.$store.state.auth.applicationId}`).then(res => {
        this.questions = res.data
        this.loaded = true
        this.questions.questionOne.answers.forEach((a, index) => {
          if (a.selected) {
            this.selectedQ1Answer = index
          }
        })
        this.questions.questionThree.answers.forEach((a, index) => {
          if (a.selected) {
            this.selectedQ3Answer = index
          }
        })
      })
    },
  },
}
</script>
<style scoped>

</style>
